import { styled, css } from "styled-components";
import theme from "styled-theming";

import Icon from "components/Icon";
import { default as NavLink } from "components/Link";
import { BodyM } from "style/components/Typography";
import { MQ } from "style/mediaQueries";

export const Wrapper = styled.nav`
    display: none;

    ${MQ.FROM_L} {
        display: block;
    }
`;

export const TopNav = styled.ul`
    color: inherit;
    display: flex;
    flex-direction: row;
    margin: 0;
`;

export const LinkWrapper = styled(BodyM).attrs<{
    $visibleInTablet?: boolean;
}>(() => ({}))`
    color: inherit;
    display: ${(props) => (props.$visibleInTablet ? "block" : "none")};
    list-style: none;
    padding: 0.875rem;

    &:first-child {
        padding-left: 0;
    }

    &:last-child {
        padding-right: 0;
    }

    ${MQ.FROM_XL} {
        display: block;
    }
`;

export const NavIcon = styled(Icon)`
    display: none;

    ${MQ.FROM_XL} {
        display: inline;
    }
`;

export const linkStyle = css`
    border-bottom: none;
    color: inherit;
    cursor: pointer;
    ${theme("theme", {
        white: css`
            filter: var(--shadow-on-image);
        `,
    })}

    span {
        transition: border-color 0.2s ease-in-out;
        border-bottom: 1px solid var(--color-black-o00);
    }

    svg {
        vertical-align: top;
    }

    &:hover {
        span {
            border-color: var(--text-on-neutral-primary);
        }
    }

    &:focus-visible {
        outline: 7px solid var(--button-focus);
        outline-offset: 7px;
    }

    &.active {
        span {
            border-bottom: 1px solid var(--text-on-neutral-link-alt-border);

            &:hover {
                border-color: var(--text-on-neutral-primary);
            }
        }
    }
`;

export const Link = styled(NavLink)`
    ${linkStyle}
    transition: color 0s;
`;

export const Button = styled.button`
    all: unset;
    ${linkStyle}
`;
