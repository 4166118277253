import React, { ReactElement } from "react";
import { ThemeProvider } from "styled-components";

import { convertToValidID } from "utils/convertToValidID";

import {
    Wrapper,
    TopNav,
    LinkWrapper,
    Link,
    Button,
    NavIcon,
} from "./Navigation.styled";
import NavigationProps from "./NavigationProps";

const Navigation = ({
    setActiveNav,
    activeNav,
    navigation,
    label,
    navigateHandler,
    id,
    theme,
}: NavigationProps): ReactElement => {
    const handleKeyPress = (
        event: React.KeyboardEvent<HTMLButtonElement>,
        nav: string,
        navText?: string,
    ): void => {
        event.preventDefault();
        if (event?.key === "Enter") setActiveNav(nav, navText);
    };

    const handleClick = (
        event: React.MouseEvent<HTMLButtonElement>,
        nav: string,
        navText?: string,
    ): void => {
        event.preventDefault();
        setActiveNav(nav, navText);
    };

    return (
        <ThemeProvider
            theme={{
                theme: theme,
            }}
        >
            <Wrapper aria-label={label}>
                <TopNav id={id}>
                    {navigation?.items?.map((item, index) => {
                        const navId = `nav${index}`;
                        const open = activeNav == navId;
                        const subNavId = convertToValidID(
                            item.displayName || navId,
                        );
                        return (
                            <LinkWrapper
                                as="li"
                                key={navId}
                                $visibleInTablet={!!item.visibleInTabletMenu}
                            >
                                {item.items && item.items.length > 0 ? (
                                    <Button
                                        key={`button${index}`}
                                        title={item.displayName}
                                        onClick={(event) =>
                                            handleClick(
                                                event,
                                                navId,
                                                item.displayName,
                                            )
                                        }
                                        onKeyPress={(event) =>
                                            handleKeyPress(
                                                event,
                                                navId,
                                                item.displayName,
                                            )
                                        }
                                        aria-expanded={open ? "true" : "false"}
                                        aria-controls={`subnav-${subNavId}`}
                                    >
                                        <span>{item.displayName}</span>

                                        <NavIcon
                                            icon={
                                                open
                                                    ? "arrowUp28"
                                                    : "arrowDown28"
                                            }
                                        />
                                    </Button>
                                ) : (
                                    <Link
                                        key={`link${index}`}
                                        to={item.url}
                                        isNavigation={true}
                                        exact
                                        title={item.displayName}
                                        hoverSpan={true}
                                        onClick={(_) => {
                                            if (navigateHandler) {
                                                navigateHandler(
                                                    1, //level
                                                    item.displayName,
                                                );
                                            }
                                        }}
                                    >
                                        <span>{item.displayName}</span>
                                    </Link>
                                )}
                            </LinkWrapper>
                        );
                    })}
                </TopNav>
            </Wrapper>
        </ThemeProvider>
    );
};

export default React.memo(Navigation);
